@import 'nprogress/nprogress.css';
@import 'keen-slider/keen-slider.min.css';

#nprogress .bar {
  background: #fff;
  height: 3px;
  z-index: 10000;
}

#nprogress .peg {
  box-shadow: 0 0 10px #fff, 0 0 5px #fff;
}
