@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: max(1.1111111111111112vw, 16px);
  --scrollbarWidth: 8px;
  --window-height: 100vh;
  --border: #BBBBBB;
  --background: white;
  --foreground: black;
  --foreground-invert: white; 
}

html {
  scroll-behavior: initial;
}

html,
body {
  padding: 0;
  margin: 0;
}

main {
  font-family: var(--body-font);
  border-color: var(--border);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

.srOnly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.reset-button {
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: left;
}

.font-feature-zero {
  font-feature-settings: 'case' on, 'zero' on;
}

.font-feature-zero-tabular {
  font-feature-settings: 'case' on, 'zero' on, 'ss04' on;
}

.font-serif-feature-zero {
  font-feature-settings: 'zero' on, 'ordn' on;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 0.8em;
  height: 0.8em;
  margin: 0.1em;
  border: 0.1em solid currentColor;
  border-radius: 50%;
  animation: spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--background);
}
.transparent-scrollbar::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 4px;
}

.small-scrollbar::-webkit-scrollbar { 
  width: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

strong {
  font-weight: 700
}

/* .font-serif sup, .font-serif sub {
  font-weight: 350
} */

.age-gate-dashed-line {
  background: linear-gradient(to right, currentColor 80%, transparent 80%);
  background-size: 0.67em 2px;
  background-repeat: repeat-x;
}

.disable-scrollbar::-webkit-scrollbar {
  display: none;
}

.disable-scrollbar {
  scrollbar-width: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

input.hide-spinner::-webkit-outer-spin-button,
input.hide-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.hide-spinner {
  -moz-appearance:textfield;
}

.placeholder-opacity-40 input::placeholder, input.placeholder-opacity-40::placeholder {
    opacity: 0.4;
    color: currentColor;
}

/* rgba(229, 229, 229, 0.4) */

.evidon-banner {
  font-family: var(--body-font) !important;
  background: var(--menu-bg) !important;
  color: var(--foreground) !important;
  width: calc(100% - 32px) !important;
  left: 50% !important;
  bottom: 16px !important;
  transform: translateX(-50%);
  max-width: 28em !important;
  font-size: 14px !important;
  text-align: center;
  -webkit-backdrop-filter: blur(25px) saturate(2);
  backdrop-filter: blur(25px) saturate(2);
  padding: 16px 16px;
}

.evidon-banner-title,
.evidon-banner-message {
  font-family: var(--body-font) !important;
  color: var(--foreground) !important;
  max-width: 38em !important;
  /* padding-bottom: 1rem !important;
  padding-top: 1rem !important; */
}

.evidon-banner-message a {
  color: var(--foreground) !important;
  font-weight: 400 !important;
  text-decoration: underline !important;
}

.evidon-banner-optionbutton,
.evidon-banner-acceptbutton {
  font-family: var(--body-font) !important;
  border-radius: 0 !important;
  font-weight: 550 !important;
  letter-spacing: 0.02em !important;
  border: 1px solid currentColor !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
  background: transparent !important;
  color: var(--foreground) !important;
  /* margin-bottom: 1rem !important; */
}

.evidon-banner-optionbutton:hover,
.evidon-banner-acceptbutton:hover {
  background: var(--foreground) !important;
  color: var(--background) !important;
}

#_evh-ric, #_evh-link { display:none !important; } 

.nprogress-busy * {
  cursor: wait !important
}
